/* Footer Estilos Gerais */
.footer {
  background-color: #CAAC79;
  color: #fff;
  padding: 40px 20px;
  text-align: center;
  font-size: .7rem;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}


.footer .logo img {
  width: 5rem;
}

.footer-text {
  margin-top: -.8rem;
  margin-bottom: -1.2rem;
}





/* Estilos responsivos */
@media (min-width: 768px) {
  .footer-content {
    flex-direction: column; /* Alinha ao centro em telas maiores também */
    justify-content: center;
    align-items: center;
    gap: 20px;
  }

  .footer .logo {
    margin-right: 0;
  }

  .footer .social {
    justify-content: center;
  }
}

.footer-text {
  margin-top: 0rem;
  margin-bottom: 0rem;
}

/* Ajuste para telas muito pequenas */
@media (max-width: 480px) {

  .footer-content {
    flex-direction: column; /* Alinhar verticalmente em telas muito pequenas */
    padding: 20px;
  }

  .footer .social {
    flex-direction: column;
    gap: 15px;
  }

  .footer-text {
    font-size: 0.7em; /* Tamanho menor para dispositivos pequenos */
  }

  .footer .logo img {
    width: 4rem; /* Ajusta o tamanho da logo para dispositivos menores */
  }
}
