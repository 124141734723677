.formulario {
  width: 100vw  ;
  padding: 50px 150px;
  background-color: #f9f9f9;
}

.formulario-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}

.formulario-left,
.formulario-right {
  flex: 1;
  min-width: 300px;
  margin: 10px;
}

.formulario-left h3 {
  font-size: 1.2rem;
  color: #333333;
  margin-bottom: 1rem;
  text-align: left;
  margin-bottom: 2rem;
}

.formulario form {

  max-width: 600px;
  margin: 0 auto;
}

.formulario input,
.formulario textarea {
  width: 100%;
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.formulario button {
  width: 100%;
  padding: 15px 30px;
  color: white;
  background-color: #CAAC79;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s, border 0.3s;
  display: flex; /* Flexbox para alinhar ícone e texto */
  align-items: center; /* Alinha verticalmente ícone e texto */
  justify-content: center; /* Centraliza o conteúdo */
  gap: 10px; /* Espaçamento entre o ícone e o texto */
}

.formulario button:hover {
  background-color: transparent;
  color: #CAAC79;
  border: 1px solid #CAAC79;

}

/* Estilo para a coluna direita (informações de contato) */
.formulario-right {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%; /* Certifique-se de que a altura esteja definida corretamente */
  margin: auto; /* Centraliza horizontalmente */
}

.formulario-right p {
  margin-bottom: -.2em;
}

.logo-agencia {
  width: 150px;
  margin-bottom: 20px;
}

.social-icons {
  display: flex;
  gap: 15px;
  margin-top: 10px;
}

.social-icons a {
  font-size: 2em;
  color: #CAAC79;
  transition: transform 0.3s ease;
}

.social-icons a:hover {
  transform: scale(1.1);
}

.whatsapp-icon {
  color: #CAAC79;
}

.instagram-icon {
  color: #CAAC79;
}

.cadastur {
  margin-top: 1.5rem;
}

@media (max-width: 768px) {
  .formulario {
    padding: 2rem 2rem; /* Reduz o padding em telas pequenas */
  }

  .formulario-container {
    flex-direction: column; /* Alinhar em coluna em telas menores */
  }

  .formulario-left, .formulario-right {
    flex: 1;
    min-width: 100%; /* Assegura que os itens ocupem toda a largura em mobile */
    margin: 0;
  }

  .formulario-left{
    margin-bottom: 5rem; 
  }

  .formulario button {
    width: 100%; /* Botão ocupa toda a largura */
    padding: 10px;
  }
}
