/* Seção Hero - Estilos Gerais */
.hero-section {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: url('../assets/fundo.png');
  background-size: cover;
  background-position: center;
  overflow: auto;
  color: white;
  padding: 20px; /* Adicionado padding para evitar corte em telas menores */
}

.hero-content {
  text-align: center;
}

.hero-content h1 {
  font-size: 3rem; /* Ajuste do tamanho do texto */
  color: white;
  font-weight: 100;
}

.hero-content h2 {
  font-weight: 100;
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
}

.btn-primary {
  background-color: #33333300;
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  border: 1px solid white;
  font-weight: 100;
  font-size: large;
}

/* Responsividade - Estilos para Telas Pequenas */
@media (max-width: 1000px) {
  .hero-content h1 {
    font-size: 2rem; /* Reduzindo o tamanho do texto em dispositivos menores */
  }

  .hero-content p {
    font-size: 1.2rem;
  }

  .btn-primary {
    padding: 8px 16px; /* Reduzindo o tamanho do botão */
    font-size: medium;
  }
}

/* Responsividade - Estilos para Telas Muito Pequenas */
@media (max-width: 700px) {
  .hero-content h1 {
    font-size: 1.5rem; /* Ajuste para dispositivos muito pequenos */
  }

  .hero-content p {
    font-size: 1rem;
  }

  .btn-primary {
    padding: 6px 12px;
    font-size: small;
  }
}
