.whatsapp-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: 100;
    color: white;
    border: 1px solid white;
    background: transparent;
    transition: background-color 0.3s, border 0.3s;
    text-decoration: none;
  }

  
  
  .whatsapp-button:hover {
    background-color: #CAAC79;
    border: none;
    color: white;

    .whatsapp-icon {
      color: white;  
    }
  }
  
  .whatsapp-icon {
    margin-right: 8px; /* Espaço entre o ícone e o texto */
    font-size: 24px;
    color: white;
  }
  