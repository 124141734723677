/* Estilos gerais da navbar */
.header {
  width: 100%;
  background-color: #f5caa100; /* Cor inicial da navbar */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 150px;
  z-index: 1000;
  position: fixed;
  height: 80px; /* Ajuste a altura */
  box-sizing: border-box;
  transition: background-color 0.3s ease; /* Suavizando a transição de cor */
}

/* Cor da navbar ao rolar */
.header.scrolled {
  background-color: #CAAC79; /* Cor da navbar após rolar */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.nav_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

/* Estilos para o lado esquerdo: Logo + Nome da Agência */
.nav__left {
  display: flex;
  align-items: center;
}

.nav__logo {
  width: 50px;
  height: auto;
  margin-right: 15px;
}

.nav__brand {
  font-size: 1.3rem;
  color: #ffffff;
  font-weight: normal;
  white-space: nowrap; /* Evita quebra de linha no nome da agência */
}

/* Estilos para o menu de navegação */
.nav__menu {
  display: flex;
  align-items: center;
}

.nav__list {
  display: flex;
  gap: 30px;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.nav__link {
  color: #ffffff;
  text-decoration: none;
  font-size: 1.2rem;
}

/* Ícone de Menu Móvel */
.nav__toggle {
  display: none;
  font-size: 1.8rem;
  cursor: pointer;
}

/* Escondendo o ícone de fechar (nav__close) em telas grandes */
.nav__close {
  display: none;
}

/* Menu Responsivo para telas pequenas */
@media (max-width: 1000px) {
  .nav__menu {
    position: fixed;
    top: 0;
    right: 0;
    width: 50%;
    height: 100vh;
    background-color: #CAAC79; /* Fundo escuro para o menu móvel */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: translateX(100%);
    transition: transform 0.3s ease;
  }

  .nav__menu.show-menu {
    transform: translateX(0); /* Exibe o menu móvel quando o ícone de menu é clicado */
  }

  .nav__list {
    flex-direction: column;
    gap: 20px;
  }

  .nav__link {
    font-size: 2rem;
    color: #fff;
  }

  .nav__close {
    display: block;
    position: absolute;
    top: 20px;
    right:2rem;
    font-size: 2rem;
    cursor: pointer;
    color: white;
  }

  .nav__toggle {
    display: block;
    font-size: 2rem;
    color: #ffffff;
  }

  /* Ajuste do nome da agência para mobile */
  .nav__brand {
    font-size: 1.2rem;
  }
}


/* Ajuste para dispositivos menores que 600px */
@media (max-width: 600px) {
  .header {
    padding: 10px; /* Reduz o padding para caber em telas menores */
    width: 100vw; /* Garante que a navbar ocupa toda a largura da tela */
  }

  .nav__left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .nav__logo {
    width: 30px; /* Ajusta o tamanho do logo */
  }

  .nav__brand {
    font-size: .8rem;
  }

  .nav__list {
    flex-direction: column; /* O menu agora será uma coluna em telas pequenas */
    gap: 20px;
    margin: 0; /* Remover margens desnecessárias */
  }

  .nav__link {
    font-size: 1.5rem;
  }

  .nav__toggle {
    font-size: 2rem;
  }
}

