.header-container {
    display: flex;
    align-items: center; /* Alinha o quadrado e o texto verticalmente */
    gap: 10px; /* Espaçamento entre o quadrado e o texto */
  }
  
  .square {
    width: 20px;
    height: 20px;
    background-color: #CAAC79; /* Cor semelhante ao quadrado da imagem */
  }
  
  h1 {
    font-family: Arial, sans-serif;
    color: #CAAC79; /* Cor do texto semelhante à cor do quadrado */
    font-size: 2rem;
    margin: 0;
    font-weight: 10; /* Remove o espaçamento padrão do h1 */
  }
  