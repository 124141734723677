/* Layout principal */
.sobre {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 50px 150px;
  background-color: #f9f9f9; /* Fundo claro */
  min-height: 100vh;
  gap: 20px;
}

/* Texto do lado esquerdo */
.text {
  flex: 1;
  text-align: start;
}

.text h2 {
  font-size: 2.5rem;
  color: #d4b27d; /* Cor do título */
  margin-bottom: 1rem;
}

.text h3 {
  font-size: 1.5rem;
  color: #333333;
  margin-bottom: 1rem;
}

.text p {
  font-size: 1.2rem;
  color: #666666;
  margin-bottom: 3rem;
}

.btn-primary {
  background-color: #d4b27d; /* Cor do botão */
  color: white;
  padding: 12px 25px;
  text-decoration: none;
  border-radius: 5px;
  border: none;
  font-size: 1rem;
  cursor: pointer;
}

.imagens {
  flex: 1;
  display: flex;
  justify-content: center;
}

.imagens img {
  max-width: 100%;
  height: auto;
}

/* Responsividade para dispositivos médios (tablets) */
@media (max-width: 1024px) {
  .sobre {
    flex-direction: column;
    text-align: center;
    padding: 20px;
  }

  .text {
    margin-bottom: 20px;
  }

  .text h2 {
    font-size: 2rem;
  }

  .text p {
    font-size: 1rem;
  }

  .btn-primary {
    font-size: 0.9rem;
    padding: 10px 20px;
  }

  .imagens {
    justify-content: center;
    margin-top: 20px;
  }

  .imagens img {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
}

/* Responsividade para dispositivos pequenos (smartphones) */
@media (max-width: 768px) {
  .sobre {
    padding: 10px;
  }

  .text h2 {
    font-size: 1.8rem;
  }

  .text p {
    font-size: 1rem;
  }

  .btn-primary {
    font-size: 0.8rem;
    padding: 8px 18px;
  }

  .imagens img {
    width: 100%;
    border-radius: 10px;
  }
}

@media (max-width: 480px) {

  .sobre{
    padding: 2rem;
  }

  .text h2 {
    font-size: 1.5rem;
  }

  .text p {
    font-size: 0.9rem;
  }

  .btn-primary {
    font-size: 0.7rem;
    padding: 6px 14px;
  }

  .imagens img {
    width: 100%;
  }
}

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 100;
  color: white;
  border: 1px solid white;
  background-color: #CAAC79;
  transition: background-color 0.3s, border 0.3s;
  text-decoration: none;
}

.button:hover {
  background-color: #caac7900;
  border: 1px solid #CAAC79;
  color: #CAAC79;

  .icon {
    color: #CAAC79;
  }
}

.icon {
  margin-right: 8px; /* Espaço entre o ícone e o texto */
  font-size: 24px;
  color: #fff;
}

