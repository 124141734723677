/* Seção de serviços */
.services-section {
  padding: 50px;
  text-align: center;
  padding: 50px 150px;
}

.services-section h2 {
  font-size: 2.5rem;
  color: #d4b27d;
  margin-bottom: 1rem;
}

.services-section p {
  text-align: left;
  font-size: 1.2rem;
  margin-bottom: 3rem;
  color: #666666;
}

/* Grid de serviços */
.services-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Exibe 3 colunas */
  gap: 20px;
}

.service-card {
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  text-align: left;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.service-card:hover {
  transform: translateY(-5px);

  h3 {
    color: #CAAC79;
  }
}

.service-card img {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 5px;
}

.service-card h3 {
  font-size: 1.5rem;
  margin-top: 1rem;
  color: #333333;
}

.service-card p {
  font-size: 1rem;
  color: #666666;
  margin-top: 0.5rem;
}

/* Responsividade para telas médias */
@media (max-width: 1024px) {
  .services-section {
    padding: 30px; /* Reduz o padding em telas médias */
  }

  .services-grid {
    grid-template-columns: repeat(2, 1fr); /* Exibe 2 colunas */
  }

  .service-card img {
    height: 140px;
  }
}

/* Responsividade para tablets */
@media (max-width: 768px) {
  .services-section {
    padding: 20px; /* Reduz mais o padding para tablets */
  }

  .services-grid {
    grid-template-columns: 1fr; /* Exibe 1 coluna */
  }

  .service-card img {
    height: 130px;
  }

  .services-section h2 {
    font-size: 2rem;
  }

  .services-section p {
    font-size: 1.1rem;
  }
}

/* Responsividade para smartphones */
@media (max-width: 480px) {
  .services-section {
    padding: 2rem; /* Padding mínimo em dispositivos móveis */
  }

  .services-section h2 {
    font-size: 1.8rem;
  }

  .services-section p {
    font-size: 1rem;
  }

  .service-card img {
    height: 120px;
  }
}
